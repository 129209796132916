
import React, { useState, useMemo } from "react"
import { useDropzone } from "react-dropzone"
import "./DragDropDiv.scss";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: "12rem",
    alignItems: 'center',
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#c3c3c3',
    borderStyle: 'dashed',
    backgroundColor: '#10566c',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function DragDropDiv(props) {
    const [files, setFiles] = useState([])

    const { getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject } = useDropzone({
            accept: "image/*",
            onDrop: (acceptedFiles) => {
                props.onDropImage(acceptedFiles);
            },
        })
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    /*const images = files.map((file) => (
        <div key={file.name}>
            <div>
                <img className="imageStyle" src={file.preview} style={{ width: "60%", padding: "20px" }} alt="preview" />
            </div>
        </div>
    ))*/
//                <div className="col-10 col-md-8" {...getRootProps({ style })}>    
//                    <input {...getInputProps()} />
    return (
        <div className=" dragdrop " {...getRootProps({ style })} >
            {/*<div className="row justify-content-center" >*/}
                {/*<div className="col-1"></div>
                <div className="col-10 col-md-8" {...getRootProps({ style })}> */}

                    <div className="drp-img-text">Drop an Image Here</div>
                        {/*<p className="d-none d-md-block" >{pasteCommandText}</p>*/}
                {/*</div>
                <div className="col-1"></div>*/}
            {/*</div>*/}
        </div>
    )
}

export default DragDropDiv
