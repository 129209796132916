import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
/*import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { useAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";*/

function NavbarCustom(props) {
  /*const auth = useAuth();

  const router = useRouter();*/

  return (
    <Navbar className="navbar-style" bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="30"
            ></img>
          </Navbar.Brand>
        </LinkContainer>

        { /*<Navbar.Toggle
          aria-controls="navbar-nav"
          className="border-0"
        ></Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="mr-1">
            <LinkContainer to="/about">
              <Nav.Link active={false}>About</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/pricing">
              <Nav.Link active={false}>Pricing</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/faq">
              <Nav.Link active={false}>FAQ</Nav.Link>
            </LinkContainer>

            {!auth.user && (
              <LinkContainer to="/auth/signin">
                <Nav.Link active={false}>Sign in</Nav.Link>
              </LinkContainer>
            )}

            {auth.user && (
              <NavDropdown id="dropdown" title="Account" alignRight={true}>
                <LinkContainer to="/dashboard">
                  <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/settings">
                  <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
                </LinkContainer>

                <Dropdown.Divider></Dropdown.Divider>

                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
          </Nav>

          {!auth.user && (
            <Button
              variant="primary"
              onClick={() => {
                router.push("/auth/signup");
              }}
            >
              Sign Up
            </Button>
          )}
            </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
