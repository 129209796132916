import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Faq
          items={[
            {
              question: "How to use?",
              answer:
                [" Drag & Drop an Image or take a screenshot and paste it here with CTRL+V / COMMAND+V",  
                "Select the area you want to crop",
                "Click 'Save' for downloading your copped image",
                "You can also copy the selected area with CTRL+C / COMMAND+C"],
            },
            {
              question: "Credits",
              answer:
                ["CropHere.com built with ReactJS", "Bootstrap, react-bootstrap", "github.com/DominicTobias/react-image-crop", "Flaticon(for logo)", "And hosted on Netlify.com"]
            },
          ]}
        ></Faq>
      </Container>
    </Section>
  );
}

export default FaqSection;
