export function apiRequest(path, method = "GET", data) {
  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

export function isMacOS()  {
    if (window.navigator.userAgent.indexOf("Mac") !== -1) {
        //OSName="Mac/iOS";
        return true;
    }
    return false;
}

export function calculateNewSize(srcImgWidth, srcImgHeight, resizeTo){
    let ratio = resizeTo/100;
    let newWid = srcImgWidth * ratio;
    let newHei = srcImgHeight * ratio;
    //console.log(srcImgWidth, '----resizeTo:', resizeTo, "  ratio=", ratio, "--", newWid );
    return { wid: newWid, hei:newHei };
}