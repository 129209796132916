import ToolbarSection from "../components/ToolbarSection";
import React, { PureComponent } from 'react';
import Section from "../components/Section";
import Container from "react-bootstrap/Container";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//import resizeImage from 'resize-image';
import { calculateNewSize, isMacOS } from '../util/util';
//import DragDropArea from "../components/DragDropArea";
import DragDropDiv from "../components/DragDropDiv";
import Dropzone from 'react-dropzone'
import Mousetrap from 'mousetrap';
//import Pica from 'pica';

const DOWNLOAD_IMAGE_NAME = "crophere-com.";

class IndexPage extends PureComponent {

  constructor(props) {
    super(props);
    //        srcImgWidth: -1, srcImgHeight: -1,
    this.state = {
        orginalImage :null,
        srcImgData: null,
        locked: false,
        orgFileName:DOWNLOAD_IMAGE_NAME,
    
        cropScaledToOrigImage: {
          x:50,
          y:50,
          width: 100,
          height: 100,
        },
        visualCrop: {
            x:50,            y:50,
            width: 100,     height: 100,
        }
      };
      this.reactCropImageRef = null;
  }
  componentDidMount() {
        this.addPasteListener();

        let main = this;
        /*Mousetrap.bind(['command+k', 'ctrl+k'], function() {
            console.log('command k or control k');
                // return false to prevent default browser behavior
            // and stop event from bubbling
            return false;
        });*/
        Mousetrap.bind(['command+c', 'ctrl+c'], function() {
            console.log('command c or control c');
            main.copyToClipboard();
            return false;
        });

  }
  getHorizontalRatio = () => {
        let hRatio = this.state.orginalImage.width / this.reactCropImageRef.width;
        return hRatio;
  }
  getVerticalRatio = () => {
        let vRatio = this.state.orginalImage.height / this.reactCropImageRef.height;
        return vRatio;
  }

  updateVisualCrop = (vcrop) => {
    this.setState({visualCrop: vcrop});

    let nx = parseInt(vcrop.x * this.getHorizontalRatio() );
    let ny = parseInt(vcrop.y * this.getVerticalRatio() );
    this.setCropLocation(nx, ny);

    let nWid = parseInt( vcrop.width * this.getHorizontalRatio() );
    let nHei = parseInt( vcrop.height * this.getVerticalRatio() );
    this.setCropDim(nWid, nHei)
  }
// ------ copy to clipboard
copyToClipboard =() => {

    const  imgFormat = 'image/png';   
    const fileName = DOWNLOAD_IMAGE_NAME;
    //console.log(this.state.orginalImage);
    if(this.state.orginalImage){
        this.getCroppedImg(
            this.state.orginalImage,
            this.state.cropScaledToOrigImage,
            fileName,
            imgFormat, 
            function(blobdata){
                //console.log(blobdata);
                window.copyToClipboard(blobdata);
            });
    }
}  


// ------------------ on CTRL + V  CTRL + C
addPasteListener = () => {
    let main = this;

    document.getElementById('pasteArea').oncopy= function(event) {
        console.log('from PasteAreay oncopy');
        //main.copyToClipboard();        
    }
    document.getElementById('pasteArea').onpaste = function (event) {
        // use event.originalEvent.clipboard for newer chrome versions
        var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
        //console.log(JSON.stringify(items)); // will give you the mime types
        // find pasted image among pasted items
        var blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
                blob = items[i].getAsFile();
            }
        }
        // load image if there is a pasted image
        if (blob !== null) {
            /*var reader = new FileReader();
            reader.onload = function(event) {
                console.log(event.target.result); // data url!
                //document.getElementById("pastedImage").src = event.target.result;
                main.readFromReader(event.target.result);
            };
            reader.readAsDataURL(blob);*/
            main.readFromReader(blob);
        }
        }
    }
// ----------------------------    
  readFromReader = filedata => {
        const reader = new FileReader();  
        let main = this;
        let originalFileName = filedata.name;
        reader.addEventListener('load', () =>
            {
                let imgObj = new Image();
                imgObj.src = reader.result;
                //main.setState({ orginalImage: imgObj, srcImgData: reader.result });
                imgObj.onload = function(event) {
                    //main.setState({srcImgWidth: this.width, srcImgHeight: this.height});
                    main.setState({ orginalImage: imgObj,  srcImgData: reader.result, orgFileName:originalFileName });

                    //console.log(">--Image-2--->", imgObj.width, imgObj.height);
                }
            });
        reader.readAsDataURL(filedata);
  }
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.readFromReader(e.target.files[0]);
    }
  }
  // ---------
  onDropImage = files => {
    /*files.forEach((imageFile) => {
        this.setSelectedFileInState(imageFile);
    })*/
    this.readFromReader(files[0]);
  }
  //------------------------- 
  onSave = e => {
        this.makeClientCrop().then(croppedImageUrl => {
            //if(this.state.croppedImageUrl){   
            if(croppedImageUrl){   
                let tempLink = document.createElement('a');
                tempLink.href = croppedImageUrl;
                tempLink.setAttribute('download', this._getSaveFileName());
                tempLink.click();
            } else {
                alert('Please add image');
            }            
        });
  }
 setCropLocation = (x, y) => {
    let w = this.state.cropScaledToOrigImage.width;
    let h = this.state.cropScaledToOrigImage.height;

    this.setState({
        cropScaledToOrigImage:{
            x: parseInt(x),
            y: parseInt(y),
            width:w,
            height:h
            }
        });
 }
 setCropDim = (w, h) => {
    let x = this.state.cropScaledToOrigImage.x;
    let y = this.state.cropScaledToOrigImage.y;

    this.setState({
        cropScaledToOrigImage:{
                x: x,
                y: y,
                width: parseInt(w),
                height: parseInt(h)
            }
        });
 }
 resetCropVariables = () => {
    let tcrop = {   x: 48,    y: 49,   width:201,    height:102  } 
    this.setState({ visualCrop: tcrop });
    // should also set the cropScaledToOrigImage according to above values
    this.setCropLocation(tcrop.x * this.getHorizontalRatio(), tcrop.y * this.getVerticalRatio());
    this.setCropDim(tcrop.width * this.getHorizontalRatio(),  tcrop.height * this.getVerticalRatio());
    /*this.setState({ cropScaledToOrigImage:{   
                x: (tcrop.x * this.getHorizontalRatio()),   
                y: (tcrop.y * this.getVerticalRatio()),
                width:(tcrop.width * this.getHorizontalRatio()),
                height:(tcrop.height * this.getVerticalRatio())
            }   
        });*/
    }
 setCropX = v => {    this.setCropLocation( parseInt(v), this.state.cropScaledToOrigImage.y);        }
 setCropY = v => {    this.setCropLocation( this.state.cropScaledToOrigImage.x, parseInt(v));        }
 setCropWidth  = v => {    this.setCropDim( parseInt(v), this.state.cropScaledToOrigImage.height);   }
 setCropHeight = v => {    this.setCropDim( this.state.cropScaledToOrigImage.width, parseInt(v));    }

 onResize = resizeTo => {
 /*   if (this.state.orginalImage) {
        /-*let imgObj = new Image();
        imgObj.src = this.state.orginalImage;*-/
        //let newSize = calculateNewSize(this.state.srcImgWidth, this.state.srcImgHeight, resizeTo);
        let newSize = calculateNewSize(this.state.orginalImage.width, this.state.orginalImage.height, resizeTo);
        
        /-*var canva = document.createElement("CANVAS");
        canva.width = newSize.wid;    canva.height = newSize.hei;
        const pica = Pica();
        pica.resize(imgObj, canva).then(res => pica.toBlob(res, 'image/jpeg', 0.90))
            .then( blob =>       
            {
            this.setState({ srcImg: blob });
            }
        );*-/
        let newImgData = resizeImage.resize(this.state.orginalImage, newSize.wid, newSize.hei, resizeImage.JPEG);
        if(newImgData.length < 7){
        console.log("====== Image Data Error =========="); 
        }
        this.setState({ srcImgData: newImgData }); 
    } */
 }

  onImageLoaded = image => {
    //console.log(">==image=from-ReactCrop=>",image.width, image.height);
    this.reactCropImageRef = image;
    this.resetCropVariables();
  };

  getFileNameOnly = (fileName) => {
    const DOT = ".";
    let indexOfDot = fileName.indexOf(DOT);
    let fileNameOnly = fileName.substring(0, indexOfDot);
    if(fileNameOnly === "image")
        fileNameOnly = "CropHere-com"
    return fileNameOnly;
}

  _getSaveFileName = () => {
    let ext = this.props.saveImagetype.endsWith('jpeg') ? 'jpg' : this.props.saveImagetype;
    let fName = (this.state.orgFileName) ? this.state.orgFileName : DOWNLOAD_IMAGE_NAME;

    let fileNameWithoutExt = this.getFileNameOnly(fName);
    return fileNameWithoutExt + "." + ext;
  }
  async makeClientCrop() {
    if (this.state.orginalImage && this.state.cropScaledToOrigImage.width && this.state.cropScaledToOrigImage.height) {
      let fileName = this._getSaveFileName();
      const  imgFormat = 'image/' + this.props.saveImagetype;   
      const croppedImageUrl = await this.getCroppedImg(
        this.state.orginalImage,
        this.state.cropScaledToOrigImage,
        fileName,
        imgFormat
      );

      return croppedImageUrl;
    }
  }

  onClick(event){
      if(this.state.orginalImage){
          event.stopPropagation();
      }
      //console.log("e-1->", event);
  }
  /*setSaveType = saveImgType => {
      this.setState({ 'saveImagetype': saveImgType });
  }*/
  _getImageQuality(){
      return this.props.saveImagetype.endsWith('webp')? 0.80 : 0.92;
  }
  // ===============================================================

  // ===============================================================
  getCroppedImg(image, crop, fileName, imgFormat, callback) {

    const canvas = document.createElement('canvas');
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );
    /*
    const scaleX = image.naturalWidth / this.reactCropImageRef.width;   // image.width;
    const scaleY = image.naturalHeight / this.reactCropImageRef.height; //image.height;

console.log("Wid:", image.naturalWidth, image.width);
console.log("Hei:", image.naturalHeight, image.height);
console.log("crop{}", crop);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );*/

    let imgQuality = this._getImageQuality();

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        if(callback){

            callback(blob);

        } else {
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
        }
      }, imgFormat, imgQuality);
    });
  }
  onCropComplete = crop => {
    this.updateVisualCrop(crop);
    //console.log('--------onCropComplete');
    //this.addCopyListener();
  };
  onCropChange = (crop, percentCrop) => {
    this.updateVisualCrop(crop);
  };
  render() {
    //const { srcImgData, orginalImage } = this.state;
    let blobAvail = (this.state.orginalImage) ? true : false;
    //let ddstyle = {backgroundColor:'blue', flexGrow: 2};
    let ddstyle = {};
    let cTxt = isMacOS() ? "Command + C": "Ctrl + C";
    let vTxt = isMacOS() ? "Command + V": "Ctrl + V";
    //You can also Copy ( Ctrl+C ) Paste ( Ctrl+V ) screenshots from Clipboard
    let pasteCommandText = "You can also copy/paste screenshot from Clipboard: " + cTxt;

    return (
      <div className="IndexComp" style={{flexGrow:'1', display: 'flex'}}>

        <div style={{display: 'flex', flexDirection:'column', flexGrow:'1'}} >
            <ToolbarSection
                onChange={this.onSelectFile} 
                onSave={this.onSave}
                crop={this.state.cropScaledToOrigImage}
                size="md"
                blobAvailable={blobAvail}
                locked={this.state.locked}
                setCropX={this.setCropX}
                setCropY={this.setCropY}
                setCropWidth={this.setCropWidth}
                setCropHeight={this.setCropHeight}
                setCropDim={this.setCropDim}
                onResize={this.onResize}
            ></ToolbarSection>

            <Section id="pasteArea" className="bg-image-area" size="md" style={{display: 'flex', flexDirection: 'column', flexGrow:1,  }} >
                <Dropzone onDrop={acceptedFiles => this.onDropImage(acceptedFiles)} >
                    {({getRootProps, getInputProps}) => (
                    <Container className="col-10 col-md-6 text-center crop-Image-container" >
                        <div {...getRootProps({onClick:event => this.onClick(event), style:ddstyle})}>
                            <input {...getInputProps()} />
                            { this.state.orginalImage ? <ReactCrop
                                            className="ReactCropcrop-Image-container"
                                            style={{ maxWidth:'none !important', color:'pink' }}
                                            imageStyle = {{color: 'yellow'}}
                                            src={this.state.orginalImage.src}
                                            crop={this.state.visualCrop}
                                            ruleOfThirds
                                            locked={this.state.locked}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                            :       <DragDropDiv
                                        onDropImage={this.onDropImage}>
                                    </DragDropDiv>                            
                               
                            }
                        </div>
                        <p className="py-2 text-light d-none d-md-block">{pasteCommandText}</p>
                    </Container> 
                    )}
                </Dropzone>
            </Section>

            </div>        
 
      </div>
    );
  }
}

export default IndexPage;
