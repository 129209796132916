import React, { useState } from "react";
import "./FaqItem.scss";

function AnswersItem(props){
  let answers = props.answers.map((ans) => <li key={"key-"+ans}>{ans}</li>);
  return(<ul>{answers}</ul>);
}

function FaqItem(props) {
  const [expanded, setExpanded] = useState(true);

  return (
    <article className="FaqItem py-4" onClick={() => setExpanded(!expanded)}>
      <h4>
        <span className="text-primary mr-3">
          <i
            className={
              "fas" +
              (expanded ? " fa-minus" : "") +
              (!expanded ? " fa-plus" : "")
            }
          ></i>
        </span>
        {props.question}
      </h4>

      {expanded && <div className="mt-3"><AnswersItem answers={props.answer}></AnswersItem></div>}
    </article>
  );
}

export default FaqItem;
