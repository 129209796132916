import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import { Link } from "./../util/router.js";
import "./Footer.scss";

function ImgSaveFormat(props){
    let onSaveTypeDDChange = e => {
        props.setSaveImagetype(e.target.value)
    }   
    return(           
        <div style={{paddingRight:'3rem'}}>
                <span style={{paddingRight:'0.5rem'}}>Save as:</span>
                <select style={{paddingLeft:'1rem'}} name="imgtype" id="imgtype" onChange={onSaveTypeDDChange}>
                    <option value="jpeg">jpg</option>
                    <option value="png">png</option>
                    <option value="webp">webp</option>
              </select> 
        </div>);
}

function Footer(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
      padding="py-1"
    >
      <Container>
        <div className="FooterComponent__inner">
          <div className="copyright left d-none d-sm-block"><a className="copyrightTxt" href="https://www.tealpod.com">{props.copyright}</a></div>            
          
          <div className="social right">


            <ImgSaveFormat saveImagetype={props.saveImagetype} setSaveImagetype={props.setSaveImagetype}/>


            { /*<Link to="/about">About</Link> */}
            <Link to="/faq">FAQ</Link>
            <a style={{paddingLeft:'2rem'}}
              href="https://twitter.com/prakis"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-twitter"></i>
              </span>
            </a> 
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default Footer;
