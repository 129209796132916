import React, { useState } from "react";
//import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
//import { LinkContainer } from "react-router-bootstrap";

//import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
//import RangeSlider from 'react-bootstrap-range-slider';
//import Slider from 'react-rangeslider'
//import 'react-rangeslider/lib/index.css'

import Button from "react-bootstrap/Button";
//import Form  from "react-bootstrap/Form";
import "./ToolbarSection.scss";
//import SectionHeader from "./SectionHeader";
//import Select from 'react-select';
//import { socialResizeOptions } from "../data";


function ToolbarSection(props) {
//    const [sliderValue, setSliderValue] = useState(100);

    const c_x = (props.crop) ? props.crop.x : 51;
    const c_y = (props.crop) ? props.crop.y : 52;

    const c_wid = (props.crop) ? props.crop.width : 100;
    const c_hei = (props.crop) ? props.crop.height : 200;

    let disabledItem = (props.blobAvailable) ? "" : " ";//disabled-item ";

    /*let onDropDownChange = e => {
        let event_value = e.value.split("-");
        let d_width = event_value[0];
        let d_height = event_value[1];
        props.setCropDim(d_width, d_height)
    }*/
    /*let onResizeSliderDragComplete = e => {
        if (sliderValue !== props.resizeValue) {
            props.onResize(sliderValue)
            //console.log("onComplete-- slidervalue changed =", sliderValue);
        }
    }*/    
    /*let setResizeSliderValue = val => {
        setSliderValue(val);
    }*/

    /*let saveType = [
        { label: 'jpg', value:'jpeg' },
        { label: 'png', value:'png' },
        { label: 'webp', value:'webp' }
      ];
    let onSaveTypeDDChange = e => {
        props.setSaveType(e.value)
    }*/        

    return (
        <div
            className="bg-toolbar py-2" 
            size={props.size}
        >
            <Container fluid="sm" className="text-center">

                <Row className="justify-content-md-center">
                    <Col className="toolbar-con d-none d-md-block col-sm-1 align-self-center">
                        <form>
                            <label className="mb-0 btn btn-dark">
                                <input id="my-file-selector" type="file" className="d-none" onChange={props.onChange} />
                                Open
                            </label>
                        </form>
                    </Col> 

                    <Col sm="auto" className={disabledItem + " d-flex toolbar-con2 col-sm-8 col-lg-4 "}>
                        <Container>
                            <Row>
                                <Col className="pl-md-4 pr-md-1 toolbar-col-item ">
                                    <InputGroup size="sm" >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className="txtDim" id="inputGroup-sizing-sm" >X:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {(props.locked) ?
                                            <FormControl readOnly aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropX(e.target.value)} value={c_x} />
                                            :
                                            <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropX(e.target.value)} value={c_x} />
                                        }

                                    </InputGroup>
                                </Col>
                                <Col className="pl-md-0 pr-md-3  toolbar-col-item ">
                                    <InputGroup size="sm" >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className="txtDim" id="inputGroup-sizing-sm" >Y:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {(props.locked) ?
                                            <FormControl readOnly aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropY(e.target.value)} value={c_y} />
                                            :
                                            <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropY(e.target.value)} value={c_y} />
                                        }

                                    </InputGroup>
                                </Col>              


                                <Col className="pl-md-3 pr-md-1  toolbar-col-item ">
                                    <InputGroup size="sm" >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className="txtDim" id="inputGroup-sizing-sm" >W:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {(props.locked) ?
                                            <FormControl readOnly aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropWidth(e.target.value)} value={c_wid} />
                                            :
                                            <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropWidth(e.target.value)} value={c_wid} />
                                        }

                                    </InputGroup>
                                </Col>
                                <Col className="pl-md-0 pr-md-4 toolbar-col-item ">
                                    <InputGroup size="sm" >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className="txtDim" id="inputGroup-sizing-sm">H:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {
                                            (props.locked) ?
                                                <FormControl readOnly aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropHeight(e.target.value)} value={c_hei} />
                                                :
                                                <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={e => props.setCropHeight(e.target.value)} value={c_hei} />
                                        }
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Col>

                    { /*<Col className= "d-flex col-md-2 pl-md-2" >
                        <Container>
                            <Row className="align-items-center" >
                                <Col style={{display:'flex', alignItems: 'center'}} className= {disabledItem + " toolbar-con3"}>
                                    <img src='img.png'  className="imageStyle imgSliderSmall d-none d-md-block" alt="img" />
                                    <Slider value={sliderValue} max={200} onChangeComplete={onResizeSliderDragComplete} onChange={setResizeSliderValue} className="imgRangeSlider"/>
                                    <img src='img.png' className="imageStyle imgSliderBig d-none d-md-block" alt="img" />                                    
                                </Col>
                            </Row>
                        </Container>
                    </Col> */ }

                    { /*<Col className= {disabledItem + "toolbar-con col-md-1 p-1 align-self-center"}>
                        <Select onChange={onSaveTypeDDChange} options={saveType} defaultValue={saveType[0]} /> 
                    </Col> */ }

                    <Col className="toolbar-con col-md-1 p-1 align-self-center">
                        <Button className={disabledItem} variant="warning" size="md" onClick={props.onSave}>Save</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
/*                        <Form.Group>
                              <Form.Control size="sm" type="text" placeholder="Width" value={width} />
                        </Form.Group>*/
export default ToolbarSection;

/** 
 { /* <RangeSlider variant="dark" value={props.resizeValue} max={200} onChange={changeEvent => props.onResize(parseInt(changeEvent.target.value))} /> * /}
 { /* <Slider value={props.resizeValue} max={200}  onChange={value => props.onResize(value)} onChangeComplete={val => console.log(">-->",val)}     /> * /}

*/