import React, { useState } from "react";
import "./../styles/global.scss";
import IndexPage from "./index";
import FaqPage from "./faq";
import AboutPage from "./about";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import "./../util/analytics.js";
import { ProvideAuth } from "./../util/auth.js";
import NavbarCustom from "./../components/NavbarCustom";
import Footer from "./../components/Footer";

function App(props) {
  const [saveImagetype, setSaveImagetype] = useState('jpeg');
  return (
    <ProvideAuth>
      <Router>
        <NavbarCustom
            variant="light"
            expand="md"
            logo="logo.svg"
            ></NavbarCustom>
        <Switch>
          <Route exact path="/"><IndexPage saveImagetype={saveImagetype} /></Route>

          <Route exact path="/faq"><FaqPage /></Route>

          <Route exact path="/about" component={AboutPage} />

          <Route component={NotFoundPage} />
        </Switch>
        <Footer
            bg="white"
            textColor="dark"
            size="sm"
            bgImage=""
            bgImageOpacity={1}
            description="A short description of what you do here"
            copyright="Tealpod"
            logo="logo.svg"
            saveImagetype={saveImagetype}
            setSaveImagetype={setSaveImagetype}
        ></Footer>        
      </Router>
    </ProvideAuth>
  );
}

export default App;
